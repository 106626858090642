@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flickity/1.0.0/flickity.css');

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #eee;
  background-image: url('components/encyclopedia/assets/bitmap.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  &_information {
    padding: .5rem 3.5rem;
    display: inline-flex;
    justify-content: space-between;
    width: inherit;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;

    .logo {
      background-position: center;
      background-size: cover;
      height: 38px;
      width: 99px;
      background-image: url('components/encyclopedia/assets/matbea.svg');
    }

    .login {
      .btn {
        font-weight: 700;
        color: #000;
        background-color: #ffc825;
        border: none;
        height: 31px;
        line-height: 2.6;
        padding: 5px 24px;
        text-decoration: none;
        width: 100%;
        font-size: 1rem;
        text-align: left;
        border-radius: .25rem;
        text-transform: uppercase;
      }
      .btn:not(:disabled):not(.disabled) {
        cursor: pointer;
      }
    }
  }

}
