@mixin retina2 {
  @media (-webkit-min-device-pixel-ratio: 1.3), (device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {
    @content;
  }
}

@mixin retina3 {
  @media (-webkit-min-device-pixel-ratio: 2.3), (device-pixel-ratio: 2.3), (min-resolution: 192dpi) {
    @content;
  }
}

.encyclopedia {
  width: 100%;
  padding-top: 41px;

  &_divider {
    &_30 {
      width: 100%;
      height: 30px;
    }
    &_40 {
      width: 100%;
      height: 40px;
    }
    &_50 {
      width: 100%;
      height: 50px;
    }
    &_60 {
      width: 100%;
      height: 60px;
    }
    &_100 {
      width: 100%;
      height: 100px;
    }
    &_120 {
      width: 100%;
      height: 120px;
    }
  }

  &_column {
    width: 100%;
    max-width: 1147px;
    margin: 0 auto;
    padding: 0;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 992px) and (max-width: 1145px) {
      max-width: 992px;
    }
    @media only screen and (max-width: 992px) {
      max-width: 992px;
    }
  }

  &_background-gray {
    background-color: #F7F7F7;
  }

  &_title {
    text-align: center;
    span {
      font-size: 21px;
      color: black;
      line-height: 35px;
      font-weight: bold;
    }
  }
  &_button-link {
    text-decoration: none;
    min-width: 180px;
  }

  &_present {
    width: 100%;
    box-sizing: border-box;
    background: rgb(0,0,96);
    height: 450px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(0,36,132,1) 0%, rgba(0,0,96,1) 43%, rgba(0, 0, 100, 1) 100%);
    @media only screen and (max-width: 992px) {
      border-radius: 0;
    }
    &_icon {
      width: 36px;
      height: 36px;
      display: flex;
      margin: auto 16px auto 0;
      cursor: pointer;
      user-select: none;
      svg {
        margin: auto;
        width: 36px;
      }
    }

    &_layout {
      width: 100%;
      padding: 0 70px;
      box-sizing: border-box;
      min-height: 450px;
      background-size: contain;
      background-position-x: right;
      background-position-y: 0;
      background-repeat: no-repeat;
      @media only screen and (max-width: 992px) {
        background-position-x: center;
        background-position-y: bottom;
        background-size: 0;
      }
    }

    &_social-networks {
      width: 100%;
      margin: auto 0;
    }

    &_content {
      padding: 70px 0;
      &_subtitle {
        margin-bottom: 20px;
        span {
          font-size: 30px;
          color: white;
          font-weight: 300;
        }
      }

      &_title {
        margin-bottom: 50px;
        margin-top: 30px;
        span, h1 {
          font-size: 50px;
          color: white;
          font-weight: 600;
          @media only screen and (max-width: 992px) {
            font-size: 24px;
            line-height: 40px;
          }
        }
      }

      &_button-download {
        background-color: #FFC825;
        cursor: pointer;
        user-select: none;
        display: flex;
        min-width: 180px;
        min-height: 60px;
        width: 180px;
        border-radius: 2px;
        text-transform: uppercase;
        margin: 0 auto;
        span {
          margin: auto;
          color: black;
          font-size: 14px !important;
        }
        @media only screen and (max-width: 992px) {
          width: 100%;
          span {
            font-size: 24px;
          }
        }
        &:hover {
          opacity: .8;
        }
      }

      &_footer {
        display: flex;
        @media only screen and (max-width: 992px) {
          flex-direction: column;
        }
        a {
          text-decoration: none;
        }
        &_button {
          background-color: #FFC825;
          margin-right: 24px;
          cursor: pointer;
          user-select: none;
          display: flex;
          min-width: 180px;
          min-height: 60px;
          border-radius: 2px;
          text-transform: uppercase;
          span {
            margin: auto;
            color: black;
            font-size: 14px;
          }
          @media only screen and (max-width: 992px) {
            width: 100%;
            span {
              font-size: 24px;
            }
          }
        }
        &_icons {
          width: 100%;
          display: flex;
          height: 60px;
          margin-left: 16px;
          @media only screen and (max-width: 992px) {
            margin-top: 16px;
          }
        }

        .ya-share2 {
          &__item {
            margin: auto 16px auto 0 !important;
          }
          &__badge {
            border-radius: 50% !important;
          }
          &__icon {
            width: 36px !important;
            height: 36px !important;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }

  &_cards {
    &_wrapper {
      width: 100%;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(2, 1fr);
      @media only screen and (max-width: 992px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &_subheading {
    text-align: center;
    padding: 0 calc(100%/7);
    span {
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
    }
  }

  &_card {
    width: 100%;
    height: 200px;
    background-color: white;
    background-size: 126px;
    background-position-y: center;
    background-position-x: calc(100% - 24px);
    background-repeat: no-repeat;
    display: flex;
    border-radius: 10px;
    user-select: none;
    span {
      color: rgba(15, 97, 208, 1);
      margin: auto 40px;
      font-size: 24px;
      font-weight: 300;
      line-height: 40px;
    }
    @media only screen and (max-width: 992px) {
      height: auto;
      span {
        margin: 20px;
        color: black;
        text-shadow: white;
        font-weight: bold;
        background-color: rgba(255,255,255,0.3);
        padding-right: 24px;
        backdrop-filter: blur(4px);
      }
    }
  }

  &_benefits {
    &_wrapper {
      width: 100%;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(4, 1fr);
      @media only screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &_benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon_box {
      width: 49px;
      height: 49px;
      background: #F7F7F7;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        vertical-align: middle;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;

        &_flower {
          background-image: url('assets/flower.svg');
          width: 29.3px;
          height: 33px;
        }
        &_rocket {
          background-image: url('assets/rocket.svg');
          width: 26px;
          height: 30.27px;
        }
        &_0_percent {
          background-image: url('assets/0_percent.svg');
          width: 27px;
          height: 16px;
        }
        &_shield {
          background-image: url('assets/shield.svg');
          width: 24px;
          height: 29px;
        }
      }
    }

    .text {
      font-style: normal;
      //font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #000000;
      margin-top: 1.875rem;
    }
  }

  .gallery {
    .flickity-viewport {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 263px;
      height: 174px;
      margin-right: 30px;
      background: #FFFFFF;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      counter-increment: gallery-cell;

      /*&:before {
        display: block;
        text-align: center;
        content: counter(gallery-cell);
        line-height: 200px;
        font-size: 80px;
        color: white;
      }*/
    }
  }

  .btn_registration {
    font-weight: 400;
    white-space: nowrap;
    user-select: none;
    font-size: .86538rem;
    line-height: 1.5;
    border-radius: .25rem;
    text-decoration: none;
    background-color: #FFC825;
    cursor: pointer;
    display: flex;
    min-width: 180px;
    min-height: 60px;
    width: 180px;
    text-transform: uppercase;
    margin: 0 auto;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    color: black;

    &:hover {
      opacity: .8;
    }
  }
}

.ico {
  display: inline-block;
  vertical-align: baseline;
}
.ico-bitcoin-security {
  background-repeat: no-repeat;
  background-image: url('assets/gallery/bitcoin-security/bitmap.png');
  width: 118px;
  height: 42px;
  background-size: contain;
}

@include retina2 {
  .ico-bitcoin-security {
    background-image: url('assets/gallery/bitcoin-security/bitmap@2x.png');
  }
}

@include retina3 {
  .ico-bitcoin-security {
    background-image: url('assets/gallery/bitcoin-security/bitmap@3x.png');
  }
}

.ico-mmgp {
  background-repeat: no-repeat;
  background-image: url('assets/gallery/mmgp/bitmap.png');
  width: 103px;
  height: 35px;
  background-size: contain;
}

@include retina2 {
  .ico-mmgp {
    background-image: url('assets/gallery/mmgp/bitmap@2x.png');
  }
}

@include retina3 {
  .ico-mmgp {
    background-image: url('assets/gallery/mmgp/bitmap@3x.png');
  }
}

.ico-aif {
  background-repeat: no-repeat;
  background-image: url('assets/gallery/aif/bitmap.png');
  width: 148px;
  height: 44px;
  background-size: contain;
}

@include retina2 {
  .ico-aif {
    background-image: url('assets/gallery/aif/bitmap@2x.png');
  }
}

@include retina3 {
  .ico-aif {
    background-image: url('assets/gallery/aif/bitmap@3x.png');
  }
}

.ico-cryptum-tv {
  background-repeat: no-repeat;
  background-image: url('assets/gallery/cryptum-tv/bitmap.png');
  width: 140px;
  height: 38px;
  background-size: contain;
}

@include retina2 {
  .ico-cryptum-tv {
    background-image: url('assets/gallery/cryptum-tv/bitmap@2x.png');
  }
}

@include retina3 {
  .ico-cryptum-tv {
    background-image: url('assets/gallery/cryptum-tv/bitmap@3x.png');
  }
}

.ico-habr {
  background-repeat: no-repeat;
  background-image: url('assets/gallery/habr/bitmap.png');
  width: 97px;
  height: 35px;
  background-size: contain;
}

@include retina2 {
  .ico-habr {
    background-image: url('assets/gallery/habr/bitmap@2x.png');
  }
}

@include retina3 {
  .ico-habr {
    background-image: url('assets/gallery/habr/bitmap@3x.png');
  }
}

.ico-vc-ru {
  background-repeat: no-repeat;
  background-image: url('assets/gallery/vc-ru/bitmap.png');
  width: 116px;
  height: 86px;
  background-size: contain;
}

@include retina2 {
  .ico-vc-ru {
    background-image: url('assets/gallery/vc-ru/bitmap@2x.png');
  }
}

@include retina3 {
  .ico-vc-ru {
    background-image: url('assets/gallery/vc-ru/bitmap@3x.png');
  }
}

.ico-pro-bitcoin {
  background-repeat: no-repeat;
  background-image: url('assets/gallery/pro-bitcoin/pro-bitcoin.png');
  width: 103px;
  height: 54px;
  background-size: contain;
}

@include retina2 {
  .ico-pro-bitcoin {
    background-image: url('assets/gallery/pro-bitcoin/pro-bitcoin@2x.png');
  }
}

@include retina3 {
  .ico-pro-bitcoin {
    background-image: url('assets/gallery/pro-bitcoin/pro-bitcoin@3x.png');
  }
}

.ico-brodude-logo-black {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/brodude-logo-black.svg');
  width: 103px;
  height: 12px;
}

.ico-coinfox-box {
  display: inline-block;
  padding: 10px;
  background-color: #272f3a;
  height: 54px;
  box-sizing: border-box;
}

.ico-coinfox {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/coinfox.png');
  width: 103px;
  height: 34px;
}

.ico-cryptomine {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/mining-cryptocurrency.png');
  width: 186px;
  height: 60px;
}

.ico-altcoin-log {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/altcoin-log.png');
  width: 200px;
  height: 50px;
}

.ico-btw {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/btw-logo.png');
  width: 200px;
  height: 131px;
}

.ico-profinvestment {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/logo-profinvestment_220.png');
  width: 200px;
  height: 35px;
}

.ico-maff {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/maff.png');
  width: 70px;
  height: 70px;
}

.ico-ruscoins {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('assets/gallery/ruscoins.png');
  width: 222px;
  height: 40px;
}